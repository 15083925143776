$(function () {
	var topBtn = $('.js-scrolltop');
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
	$('.js-menu').click(function(){
		$(this).toggleClass('is-open')
		$('.js-nav').toggleClass('is-open').stop().fadeToggle();
		return false;
	})
	/* scroll */
	// #で始まるアンカーをクリックした場合に処理
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var target = $(this.hash);
		if (!target.length) return;
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
});
$(window).on('load', function () {
})
